import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams, Prompt  } from 'react-router-dom';
import './Avaliacao.css'; // Importa o arquivo CSS
import { useSelector, useDispatch } from 'react-redux';
import { setTimeLeft, incrementAttempts, resetEvaluation } from '../redux/actions/userActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Avaliacao = () => {
    const [perguntas, setPerguntas] = useState([]);
    const [respostas, setRespostas] = useState({});
    const timeLeft = useSelector(state => state.user.timeLeft);
    const [attempts, setAttempts] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const { cursoId } = useParams();
    const [correctAnswers, setCorrectAnswers] = useState(false);
    const [incorrectAnswers, setIncorrectAnswers] = useState([]);

    useEffect(() => {
        if (timeLeft <= 0 || attempts >= 3) {
          dispatch(resetEvaluation());
          
          history.push(`/aula/${cursoId}`);
           
        }
    
        const timer = timeLeft > 0 && setInterval(() => dispatch(setTimeLeft(timeLeft - 1)), 1000);
        return () => clearInterval(timer);
      }, [timeLeft, attempts, dispatch, history, cursoId]);
    
  useEffect(() => {
    const buscarPerguntas = async () => {
      try {
        const resultado = await axios.get(`${process.env.REACT_APP_API_URL}/api/cursos/${cursoId}/avaliacoes`);
        setPerguntas(resultado.data);
      } catch (error) {
        console.error('Erro ao buscar perguntas', error);
      }
    };

    buscarPerguntas();
  }, [cursoId]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (timeLeft > 0 && attempts < 3) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [timeLeft, attempts]);
  

  const handleRespostaChange = (perguntaId, opcao) => {
    setRespostas(prev => ({
      ...prev,
      [`pergunta-${perguntaId}`]: opcao, // Substitui a resposta anterior pela nova
    }));
  };

  useEffect(() => {
    const savedAnswers = localStorage.getItem('respostas');
    if (savedAnswers) {
      setRespostas(JSON.parse(savedAnswers));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('respostas', JSON.stringify(respostas));
  }, [respostas]);

  const handleChange = (e, questionId) => {
    setRespostas({
      ...respostas,
      [questionId]: e.target.value
    });
  };

  const finalizarAvaliacao = async () => {
    try {
      const resultado = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/cursos/${cursoId}/verificarAvaliacao`,
        { respostasUsuario: respostas }
      );
  
      if (resultado.data.pontuacao === resultado.data.total) {
        toast.success("Parabéns! Você acertou todas as questões");
        setCorrectAnswers(true);
        setTimeout(() => history.push('/Certificado'), 5000);
      } else {
        setAttempts(attempts + 1);
        dispatch(incrementAttempts());
  
        if (attempts === 1) { 
          const incorrect = Object.keys(resultado.data.respostasCorretas).filter(
            perguntaId => resultado.data.respostasCorretas[perguntaId] !== respostas[perguntaId]
          );
          setIncorrectAnswers(incorrect);
        }
  
        if (attempts >= 3) {
          toast.error(
            "Você falhou 3 tentativas na sua avaliação. Assista a aula novamente e refaça a avaliação."
          );
        } else {
          toast.error("Algumas respostas estão incorretas. Tente novamente.");
        }
      }
    } catch (error) {
      console.error('Erro ao verificar avaliação', error);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  

  
  return (
   <> <div className='avaliacao-inicio' style={{marginTop:'100px'}}>
   <h1>Avaliação</h1>
   <p>Responda as perguntas a seguir para finalizar o curso</p>
</div>
    <div className="avaliacao-container">
      {perguntas.map(pergunta => (
        <div className="pergunta" key={pergunta.id}>
          <p className="pergunta-texto">{pergunta.pergunta}</p>
          <div className="opcoes-container" key={`pergunta-${pergunta.id}`}> 
          {Object.entries(pergunta.opcoes).map(([letra, texto]) => (
            <label className="opcao" key={letra}>
              <input 
                type="radio"
                name={`pergunta-${pergunta.id}`}
                value={letra}
                checked={respostas[`pergunta-${pergunta.id}`] === letra}
                onChange={() => handleRespostaChange(pergunta.id, letra)}
                key={`radio-${pergunta.id}-${letra}-${respostas[`pergunta-${pergunta.id}`]}`}
              />
              {/* Inclui a letra antes do texto */}
              <span>{letra}. {texto}</span> 
            </label>
          ))}
          </div>
        </div>
      ))}
      <button className="finalizar-btn" onClick={finalizarAvaliacao}>Finalizar</button>
      <div className="status-container">
      <div className="temporizador">Tempo: {formatTime(timeLeft)}</div>

        <div className="tentativas">Tentativas: {attempts}/3</div>
        </div>
         <Prompt
                when={timeLeft > 0 && attempts < 3 && !correctAnswers} // O Prompt agora considera também o estado correctAnswers.
                message="Você tem certeza que deseja sair da avaliação? Suas respostas e tempo serão perdidos."
            />
<ToastContainer />
    </div>
    </>
  );
};

export default Avaliacao;