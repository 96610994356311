// ErrorPage.js
import React from "react";

function ErrorPage() {
  return (
    <div>
      <h1>Erro</h1>
      <p>Você não tem permissão para acessar esta página.</p>
    </div>
  );
}

export default ErrorPage;
