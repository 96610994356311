export const FETCH_USERS = 'FETCH_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_PURCHASE_STATUS = 'SET_PURCHASE_STATUS'; // Novo tipo de ação
export const SET_CURSOS_COMPRADOS = 'SET_CURSOS_COMPRADOS';
export const SET_CURSOS_ACESSO = 'SET_CURSOS_ACESSO';
export const SET_TIME_LEFT = 'SET_TIME_LEFT';
export const SET_ATTEMPTS = 'SET_ATTEMPTS';
export const RESET_EVALUATION = 'RESET_EVALUATION';
export const SET_SELECTED_CURSO = 'SET_SELECTED_CURSO'; // Novo tipo de ação
export const SET_USERNAME = 'SET_USERNAME';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const SET_AVALIACAO_RESPOSTAS = 'SET_AVALIACAO_RESPOSTAS';
export const SET_CERTIFICADO_DATA = 'SET_CERTIFICADO_DATA';
export const SET_CERTIFICADO_LOADING = 'SET_CERTIFICADO_LOADING';
export const SET_CURSOS_COMPRADOSCATALOGO = 'SET_CURSOS_COMPRADOSCATALOGO';
export const SET_RECOVERY_STAGE = 'SET_RECOVERY_STAGE';
export const SET_RECOVERY_EMAIL = 'SET_RECOVERY_EMAIL';
export const SET_RECOVERY_CODE = 'SET_RECOVERY_CODE';
export const RESET_RECOVERY_STATE = 'RESET_RECOVERY_STATE';
export const SET_USERS_EMPRESA = 'SET_USERS_EMPRESA';