import React, { useState, useEffect, useRef , useContext} from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { Player } from 'player.js';
import { Document, Page, pdfjs } from 'react-pdf';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './Aula.css';
import { AuthContext } from '../context/AuthContext';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const AulasCurso = () => {
  const { cursoId, aulaAtualId } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [aulas, setAulas] = useState([]);
  const [aulaAtual, setAulaAtual] = useState(null);
  const playerRef = useRef(null);
  const history = useHistory();
  const [statusCurso, setStatusCurso] = useState('');
  const { authState } = useContext(AuthContext);
  const userId = authState.userId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPageNumber, setModalPageNumber] = useState(1);
  
  const openModal = (pageNumber) => {
    setModalPageNumber(pageNumber);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const PdfModal = ({ isOpen, onClose, file, pageNumber }) => {
    if (!isOpen) return null;
  
    return (
      <div className="pdf-modal-backdrop" onClick={onClose}>
        <div className="pdf-modal-content" onClick={(e) => e.stopPropagation()}>
          <Document file={file}>
            <Page pageNumber={pageNumber} width={window.innerWidth * 0.8} />
          </Document>
          <button className="pdf-modal-close" onClick={onClose}>Fechar</button>
        </div>
      </div>
    );
  };
  
  const fetchAulas = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cursos/${cursoId}/aulas`);
      setAulas(response.data);
      const aulaAtualEncontrada = response.data.find(aula => aula.id.toString() === aulaAtualId);
      setAulaAtual(aulaAtualEncontrada || response.data[0]);
    } catch (err) {
      console.error('Erro ao buscar aulas:', err);
    }
  };



  useEffect(() => {
    const verificarStatusCurso = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cursos/status/${userId}/${cursoId}`);
        setStatusCurso(response.data.status);
      } catch (error) {
        console.error('Erro ao verificar status do curso:', error);
      }
    };

    verificarStatusCurso();
  }, [cursoId, userId]);

  useEffect(() => {
    if (aulaAtual && playerRef.current) {
      const player = new Player(playerRef.current);
      player.on('ready', () => {
        console.log('Player is ready!');
      });
    }
  }, [aulaAtual]);

  const [currentPage, setCurrentPage] = useState(1);

  const viewerRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getPdfFile = (cursoId) => {
    return process.env.PUBLIC_URL + `/pdf/${cursoId}.pdf`;
  };

  const goToPrevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  const goToNextPage = () => setCurrentPage(currentPage < numPages ? currentPage + 1 : numPages);

  // Calcula a largura do PDF baseada na largura da janela
  const pdfWidth = windowWidth > 768 ? 600 : windowWidth - 40; // Subtrai 40px para padding

  const handlePainel = () => {
    history.push('/usuario/central');
  };

  const handleNavegarAula = (direcao) => {
    const indiceAtual = aulas.findIndex(aula => aula.id === aulaAtual.id);
    const novoIndice = indiceAtual + direcao;
    if (novoIndice >= 0 && novoIndice < aulas.length) {
      const aulaSelecionada = aulas[novoIndice];
      setAulaAtual(aulaSelecionada);

      // Atualizar o URL para a nova aula
      history.push(`/aula/${cursoId}/${aulaSelecionada.id}`);
    }
  };

  const handleFinalizarAula = async () => {
    if (statusCurso === 'concluido') {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cursos/status/${userId}/${cursoId}`);
        if (response.data.status === 'concluido' && response.data.acessos_pos_conclusao < 2) {
          // Incrementa o acesso
          await axios.post(`${process.env.REACT_APP_API_URL}/api/cursos/incrementar-acesso`, { userId, cursoId });
          // Permitir acesso à aula
        } else {
          toast.info("Você já concluiu o curso, acesse a pagina de Certificados para fazer Download do seu certificado de conclusão.", {
            position: "top-right"
          });
        }
      } catch (error) {
        console.error('Erro ao verificar o status do curso:', error);
      }
    } else {
      history.push(`/avaliacao/${cursoId}`);
    }
  };

  useEffect(() => {
    const verificarAcessoCurso = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verificar-acesso/${userId}/${cursoId}`);
        if (!response.data.temAcesso) {
          history.push('/usuario/central');
        } else {
          await fetchAulas();
          if (statusCurso === 'concluido') {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/cursos/incrementar-acesso`, { userId, cursoId });
            if (res.data.success) {
              const revisao = res.data.acessos_pos_conclusao;
              toast.info(`Você está acessando a revisão ${revisao} da aula. Após a segunda revisão seu acesso será revogado.`);
            }
          }
        }
      } catch (error) {
        console.error('Erro ao verificar acesso ao curso:', error);
      }
    };
  
    verificarAcessoCurso();
  }, [cursoId, userId, history, statusCurso]);
  
  

  const progresso = (aulas.findIndex(aula => aula.id === aulaAtual.id) + 1) / aulas.length * 100;

  return (
    <div>
      <ToastContainer />
      {aulaAtual && (
        <div className="aula-container">
          <h1 className="titulo-aula">{aulaAtual.nome}</h1>
          <button onClick={handlePainel} className="botao-fechar">Retornar ao Painel</button>
          <iframe
            ref={playerRef}
            src={`https://fast.wistia.net/embed/iframe/${aulaAtual.url_video}`}
            width="800"
            height="600"
            allowFullScreen
            frameBorder="0"
            title="Video Player"
            className="player-video"
          ></iframe>

          <div className="pdf-viewer">
            <Document file={getPdfFile(cursoId)} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={pdfWidth} />
            </Document>
            <div className="pdf-navigation">
              {currentPage > 1 && <button onClick={goToPrevPage} className="pdf-nav-button">Anterior</button>}
              {currentPage < numPages && <button onClick={goToNextPage} className="pdf-nav-button">Próxima</button>}
            </div>
          </div>

          <div className="navegacao-aulas">
            <button style={{marginBottom:'25px'}}
              onClick={() => handleNavegarAula(-1)}
              disabled={aulas[0] && aulas[0].id === aulaAtual.id}
              className="botao-aula botao-aula-anterior"
            >
              Aula Anterior
            </button>
            {aulas[aulas.length - 1] && aulas[aulas.length - 1].id === aulaAtual.id ? (
              <button style={{marginBottom:'25px'}}
                onClick={handleFinalizarAula}
                className="botao-aula botao-aula-proxima"
              >
                Finalizar
              </button>
            ) : (
              <button style={{marginBottom:'25px'}}
                onClick={() => handleNavegarAula(1)}
                disabled={aulas[aulas.length - 1] && aulas[aulas.length - 1].id === aulaAtual.id}
                className="botao-aula botao-aula-proxima"
              >
                Próxima Aula
              </button>
            )}
          </div>
        </div>
      )}
      <PdfModal
        isOpen={isModalOpen}
        onClose={closeModal}
        file={getPdfFile(cursoId)}
        pageNumber={modalPageNumber}
      />
    </div>
  );
};

export default AulasCurso;