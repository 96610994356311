import React, { createContext, useState } from 'react';

export const CursosContext = createContext();

export const CursosProvider = ({ children }) => {
  const [selectedOriginalCourses, setSelectedOriginalCourses] = useState([]);
  const [selectedPriceOptions, setSelectedPriceOptions] = useState({});

  const value = {
    selectedOriginalCourses,
    setSelectedOriginalCourses,
    selectedPriceOptions,
    setSelectedPriceOptions,
  };

  return <CursosContext.Provider value={value}>{children}</CursosContext.Provider>;
};

