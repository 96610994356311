import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './Certificado.css';

const Certificado = () => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pdfData, setPdfData] = useState(null);
  const selectedCursoId = useSelector(state => state.user.selectedCursoId);
  const username = authState.username;
  const userId = authState.userId;

  useEffect(() => {
    localStorage.removeItem('respostas');
  
    if (selectedCursoId && selectedCursoId.id && username && userId) {
      const updateAndFetchCertificate = async () => {
        try {
          const updateResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/cursos/concluir`, {
            userId,
            cursoId: selectedCursoId.id,
          }, {
            headers: {
              'Authorization': `Bearer ${authState.token}` // Incluindo o token no cabeçalho
            }
          });
  
          if (updateResponse.data.success) {
            console.log('Status do curso atualizado para concluído.');
  
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/certificado-concluido/${username}/${selectedCursoId.id}`, {
              responseType: 'blob',
              headers: {
                'Authorization': `Bearer ${authState.token}` // Incluindo o token no cabeçalho
              }
            });
  
            setPdfData(URL.createObjectURL(response.data));
            setIsLoading(false);
          } else {
            console.error('Falha ao atualizar o status do curso:', updateResponse.data.message);
            // Exiba uma mensagem de erro amigável ao usuário
            alert('Não foi possível marcar o curso como concluído. Por favor, tente novamente mais tarde.');
          }
        } catch (error) {
          if (error.response) {
            // Erro do lado do cliente (4xx) ou do servidor (5xx)
            console.error('Erro na requisição:', error.response.status, error.response.data);
            if (error.response.status === 401) {
              // Token inválido ou expirado, redirecione para login
              history.push('/login'); // Ajuste a rota se necessário
            } else {
              // Outro erro, exiba uma mensagem genérica
              alert('Ocorreu um erro ao carregar o certificado. Por favor, tente novamente mais tarde.');
            }
          } else if (error.request) {
            // A requisição foi feita, mas nenhuma resposta foi recebida
            console.error('Erro na requisição:', error.request);
            alert('Ocorreu um erro ao se comunicar com o servidor. Por favor, verifique sua conexão com a internet.');
          } else {
            // Algo aconteceu ao configurar a requisição
            console.error('Erro ao configurar a requisição:', error.message);
            alert('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');
          }
        }
      };
  
      updateAndFetchCertificate();
    }
  }, [selectedCursoId, username, userId]);

  const handleRedirect = () => {
    history.push('/usuario/central');
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/generate-historico-certificado/${userId}/${selectedCursoId.id}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${authState.token}`,
          },
        }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certificado.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o certificado:', error);
      // Consider adding error handling for the user interface
    }
  };

  return (
    <div>
      <center><h1 style={{ fontFamily: 'Montserrat', fontWeight: 'Bold', color: 'White' }}>PARABÉNS!</h1>
      <h4 style={{ fontFamily: 'Montserrat', fontWeight: 'Bold', color: 'White' }}>Você concluiu o curso, abaixo está seu certificado.</h4></center>
      {isLoading ? <p>Carregando...</p> : <iframe src={pdfData} width="100%" height="600px" />}
      <center><h6 style={{ fontFamily: 'Montserrat', fontWeight: 'Bold', color: 'White' }}>Seu certificado está disponível para DOWNLOAD na página de Certificados.</h6></center>
      
      
      {!isLoading && (
      <div className="download-btn">
        <center><button onClick={handleDownload} className="botao-aula botao-aula-proxima">
          Download Certificado
        </button></center>
      </div>
    )}

    
      
      <center><button onClick={handleRedirect} className="botao-aula botao-aula-proxima">Voltar ao Painel</button></center> 
    </div>
  );
};

export default Certificado;
