import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import {
  getAlunosCadastradosCount,
  getAcessoAlunosCount,
  getCursosCadastradosCount,
} from '../redux/actions/dashboardActions';
import './styles.dashboard.scss';
import Financeiro from './Financeiro.js';


const Dashboard = () => {
  const dispatch = useDispatch();
  const { alunosCount, acessoAlunosCount, cursosCount } = useSelector((state) => state.dashboard);
  const { username } = useSelector(state => state.user.userDetails);

  useEffect(() => {
    dispatch(getAlunosCadastradosCount());
    dispatch(getAcessoAlunosCount());
    dispatch(getCursosCadastradosCount());
  }, [dispatch]);


  // Dados para o gráfico de pizza com as cores atualizadas
  const data = {
    labels: ['Amadeu', 'Felipe', 'Marcos', 'Matheus'],
    datasets: [
      {
        data: [40, 40, 10, 10], // porcentagens para cada pessoa
        backgroundColor: ['#FF7F00', '#AA5400', '#0E1A29', '#15283E'], // cores atualizadas
        hoverBackgroundColor: ['#FF7F00', '#AA5400', '#0E1A29', '#15283E'], // cores ao passar o mouse
      },
    ],
  };


  return (
    <Container fluid>
      <center><div className='back-prof'><h1 style={{fontFamily:'Montserrat', fontSize:'28pt'}}>Seja bem-vindo {username}</h1></div></center>
      <Row className="mb-4 d-flex justify-content-center">
        <Col className='text-center card-btn h-100'>
          <h5>Alunos Cadastrados</h5>
          <Card.Text>Total de cadastros</Card.Text>
          <Card.Text className="display-4">{alunosCount}</Card.Text>
        </Col>
        <Col className='text-center card-btn h-100'>
          <h5>Acesso dos Alunos</h5>
          <Card.Text>Até o momento</Card.Text>
          <Card.Text className="display-4">{acessoAlunosCount}</Card.Text>
        </Col>
        <Col className='text-center card-btn h-100'>
          <h5>Cursos</h5>
          <Card.Text>Total</Card.Text>
          <Card.Text className="display-4">{cursosCount}</Card.Text>
        </Col>
      </Row>
      <Financeiro />
    </Container>
  );
}

export default Dashboard;
