import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './PaginaCertificados.css';
const PaginaCertificados = () => {
  const [certificados, setCertificados] = useState([]);
  const [codigo, setCodigo] = useState(''); // Estado para armazenar o código inserido pelo usuário
  const [validacao, setValidacao] = useState(null); // Estado para armazenar a resposta da validação
  const [isLoading, setIsLoading] = useState(true);
  const { authState } = useContext(AuthContext);
  const userId = authState.userId;
  const token = authState.token;

  const validarCertificado = async () => {
    if (!codigo) {
      setValidacao('Por favor, insira um código de certificado.');
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/validar-certificado/${codigo}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.isValid) {
        const dataFormatada = new Date(response.data.dataConclusao).toLocaleDateString('pt-BR');
        setValidacao(`Certificado válido!\nData de conclusão: ${dataFormatada}`);
      } else {
        setValidacao('Certificado inválido ou não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao validar o certificado:', error);
      setValidacao('Erro ao validar o certificado.');
    }
  };
  useEffect(() => {
    const fetchCertificados = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/certificados/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setCertificados(response.data);
      } catch (error) {
        console.error('Erro ao buscar certificados:', error);
      } finally {
        setIsLoading(false); // Define o carregamento como falso após a conclusão da busca
      }
    };

    if (userId && token) {
      fetchCertificados();
    }
  }, [userId, token]);

  const certificadoStyle = {
    background: '#69717A',
    fontFamily: 'Montserrat',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '15px',
    padding: '15px',
    margin: '10px 0',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  };
  
// Função para lidar com o download do certificado
const handleDownload = async (cursoId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/generate-historico-certificado/${userId}/${cursoId}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Lógica para criar um link temporário para download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'certificado.pdf');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Erro ao baixar o certificado:', error);
  }
};

  
  
  return (
    <div >
      <div className='back-aluno'><h1 className="welcome-message" style={{fontSize:'28pt'}}>Certificados</h1></div>
      <hr />

      <div className='div-certificado'>
        <p className='div-cert-text'>Cada certificado possui um código identificador único que permite validar sua autenticidade.</p>
    
        <input
          type="text"
          value={codigo}
          onChange={(e) => setCodigo(e.target.value)}
          placeholder="Digite o código do certificado"
          style={{ padding: '10px', marginBottom: '10px', width: '300px' }} // Ajuste o tamanho conforme necessário
        />
        
        <button onClick={validarCertificado} className='botao-aula botao-aula-proxima' style={{ padding: '10px 20px' }}>Validar Certificado</button>
        
          {validacao && (
            <div className='mensagem-validacao'>
              <p>{validacao.split('\n')[0]}</p>
              <p>{validacao.split('\n')[1]}</p>
            </div>
          )}

        <hr/>
        <p className='div-cert-text'>É possível autenticar o seu certificado em até dois anos a partir da conclusão do curso on-line, conforme informação descrita no “Termo de Uso e Política de Privacidade....”.  Caso tenha alguma dúvida, envie um email para: <strong>suporte.fmatch@outlook.com</strong> </p>

      </div>

      {isLoading ? ( // Verifica se está carregando
        <p>Carregando certificados...</p>
      ) : certificados.length > 0 ? (
        certificados.map((certificado) => (
          <div key={certificado.id} style={certificadoStyle}>
            <h3>{certificado.nome}</h3>
            <button className='botao-aula botao-aula-proxima' onClick={() => handleDownload(certificado.id)}>Baixar Certificado</button>
          </div>
        ))
        
      ) : (
        <p>Você ainda não concluiu nenhum curso.</p>
      )}
    </div>
  );
};
export default PaginaCertificados;
