import React, { useState, useEffect , useContext} from 'react';  // Acrescentamos o useEffect
import logo from '../images/logo2.png'; // Caminho para o logo
import cursosBackground from '../images/cursos-background.png';
import mercadopagoImg from '../images/mercadopago.png';
import sslsecureImg from '../images/sslsecure.png';
import { ToastContainer, toast } from 'react-toastify'; 
import { Link } from 'react-router-dom';
import ImageCarrinho from '../images/carrinho-de-compras.png'; // Ajuste o caminho conforme necessário
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Badge, Button, Container, Row, Col, Card, Form } from 'react-bootstrap'; // Importe Col aqui
import cursosData from './cursos.json';
import CatalogoXLSX from '../views/Catalogo Cursos EAD - Atualizado 31.05.23.xlsx';
import './Cursos.css';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { setPurchaseStatus, setCursosCompradosCatalogo } from '../redux/actions/userActions.js';
import { AuthContext } from '../context/AuthContext';
import { CursosContext } from './CursoContext';
import cursosFMATCHData from './CursosFMATCH.json';
import { FaBook, FaUsers, FaUser, FaCalendarAlt, FaMoneyBillWave, FaExclamationCircle, FaTimesCircle, FaCheckCircle, FaCertificate } from 'react-icons/fa';

function Curso() {
  
  const dispatch = useDispatch();
  const purchaseStatus = useSelector(state => state.user.purchaseStatus);
  const cursosCompradosCatalogo = useSelector(state => state.user.cursosCompradosCatalogo);
  const { authState } = useContext(AuthContext);
  const username = authState.username; // Acessando o username do contexto
  const userId = authState.userId; // Acessando o userId do contexto
  const { selectedOriginalCourses, setSelectedOriginalCourses, selectedPriceOptions, setSelectedPriceOptions } = useContext(CursosContext);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(cursosData);
    // Inicializa o estado selectedCourses como um array vazio
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [courseCount, setCourseCount] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const [cursosOriginais, setCursosOriginais] = useState([]);
 
    
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [selectedAccessPeriods, setSelectedAccessPeriods] = useState({});
   
    const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);
    const [agreedToServiceTerms, setAgreedToServiceTerms] = useState(false);
    
    
    const handleOriginalCourseSelection = (cursoId) => {
      const curso = cursosOriginais.find(c => c.id === cursoId);
      if (!curso) {
        console.error('Curso não encontrado.');
        return;
      }
    
      if (selectedOriginalCourses.includes(cursoId)) {
        // Remover curso do carrinho
        const newSelectedCourses = selectedOriginalCourses.filter(id => id !== cursoId);
        setSelectedOriginalCourses(newSelectedCourses);
    
        // Remover opções de preço do curso removido
        const newSelectedPriceOptions = { ...selectedPriceOptions };
        delete newSelectedPriceOptions[cursoId];
        setSelectedPriceOptions(newSelectedPriceOptions);
    
        toast.info("Curso removido do carrinho!");
      } else {
        // Adicionar curso ao carrinho
        setSelectedOriginalCourses([...selectedOriginalCourses, cursoId]);
        setSelectedPriceOptions({ ...selectedPriceOptions, [cursoId]: curso.valor_10d });
    
        toast.success("Curso adicionado ao carrinho!");
      }
    
      // Atualiza o contador do carrinho
      setCourseCount(selectedCourses.length + selectedOriginalCourses.length + (selectedOriginalCourses.includes(cursoId) ? -1 : 1));
    };
    
    // Atualizar esta função para lidar com a seleção do período de acesso
    const handlePriceSelection = (courseId, price, period) => {
      setSelectedPriceOptions({ ...selectedPriceOptions, [courseId]: price });
      setSelectedAccessPeriods({ ...selectedAccessPeriods, [courseId]: period }); // Novo
    };
    
    // Função para verificar o status da compra
    const checkPurchaseStatus = async (compraId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/compra/status/${compraId}`, {
          headers: {
            Authorization: `Bearer ${authState.token}` // Incluindo o token de autenticação
          }
        });
        const status = response.data.status; // Supondo que o backend retorne { status: 'aprovado' | 'pendente' | 'reprovado' }
    
        if (status === 'aprovado') {
          // Limpar o carrinho e exibir o toast de sucesso aqui
          clearCart();
          toast.success("Compra aprovada! Obrigado por comprar conosco.");
    
          // Despache a ação para atualizar a lista de cursos comprados
          const userId = authState.userId;
          const cursosCompradosCatalogoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/cursos-comprados/`, {
            headers: {
              Authorization: `Bearer ${authState.token}`
            }
          });
          dispatch(setCursosCompradosCatalogo(cursosCompradosCatalogoResponse.data.map(curso => curso.id)));
    
          // Remove event listener when purchase is approved
          window.removeEventListener('beforeunload', () => {});
        } else if (status === 'reprovado') {
          toast.error("Compra reprovada. Por favor, tente novamente.");
        }
    
        return status;
      } catch (error) {
        console.error("Erro ao verificar o status da compra:", error);
        toast.error("Erro ao verificar o status da compra. Por favor, contate o suporte.");
        return 'erro'; // Retorna 'erro' em caso de falha na requisição
      }
    };
    
    useEffect(() => {
      
  
      requestPopupPermission(); // Request pop-up permission on component load
    }, []); // Empty dependency array to run this effect only once

    const calculateTotal = () => {
      return selectedOriginalCourses.reduce((total, courseId) => {
        const curso = cursosOriginais.find((curso) => curso.id === courseId);
        if (!curso) return total; // Continua se o curso não for encontrado
    
        // Assegura que o valor seja uma string e substitui ',' por '.' antes de converter para número
        const valor = selectedPriceOptions[courseId];
        if (valor === null || valor === undefined) {
          console.error(`Valor não definido para o curso ${cursoId}`);
          return total; // Retorna o total atual se o valor não estiver definido
        }
        const valorNumerico = typeof valor === 'string' ? Number(valor.replace(',', '.')) : valor;
        return total + valorNumerico;
      }, 0);
    };
    useEffect(() => {
      const fetchcursosCompradosCatalogo = async () => {
        try {
          if (userId) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cursos-compra/`, {
              headers: {
                Authorization: `Bearer ${authState.token}` // Incluindo o token de autenticação
              }
            });
            dispatch(setCursosCompradosCatalogo(response.data.map(curso => curso.id)));
          }
        } catch (error) {
          console.error('Erro ao buscar cursos comprados:', error);
          toast.error('Erro ao carregar cursos comprados.');
        }
      };
    
      fetchcursosCompradosCatalogo();
    }, [userId, authState.token, dispatch]); // Adicionando authState.token e dispatch às dependências
    
    
      

    useEffect(() => {
      setCursosOriginais(cursosFMATCHData);
    }, []);
      


      const handleSubmit = async () => {
        const userId = authState.userId;
        
        // Preparando itens para a compra
        const items = selectedOriginalCourses.map(courseId => {
          const curso = cursosOriginais.find(curso => curso.id === courseId);
          return curso ? {
            id: curso.id,
            title: curso.nome,
            unit_price: parseFloat(curso.valor_10d.toString().replace(',', '.')),
            quantity: 1,
            periodo: '10d', 
          } : null;
        }).filter(item => item !== null);
      
        dispatch(setPurchaseStatus('pendente'));
      
        // Inicia a exibição da mensagem de compra pendente
        const toastId = toast.info("Compra Pendente: Você tem 5 minutos para realizar a compra", { autoClose: false });
      
        try {
          const { data: { comprasRegistradas, preferenceId } } = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkout`, { items, userId });
          
          // Verifica se a resposta contém os IDs de compra registrados
          if (!comprasRegistradas || comprasRegistradas.length === 0) {
            toast.error("Erro ao processar a compra. Nenhuma compra registrada.");
            return; // Interrompe a execução se não houver compras registradas
          }
      
          // Assume que o ID da primeira compra registrada é relevante para a verificação de status
          const compraId = comprasRegistradas[0];
      
          if (preferenceId) {
            const checkoutURL = `https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=${preferenceId}`;
            window.open(checkoutURL, '_blank');
          }
      
          // Adiciona um listener para antes de sair da página
          window.addEventListener('beforeunload', (event) => {
            if (purchaseStatus === 'pendente') {
              event.preventDefault();
              event.returnValue = '';
            }
          });
      
          // Periodicamente verifica o status da compra
          const intervalId = setInterval(async () => {
            const status = await checkPurchaseStatus(compraId);
            if (status !== 'pendente') {
              clearInterval(intervalId);
              toast.dismiss(toastId);
              if (status === 'aprovado') {
               
                clearCart();
                setShowModal(false);
              } else if (status === 'reprovado') {
            
              }
              dispatch(setPurchaseStatus(''));
            }
          }, 30000); // Verifica a cada 30 segundos
      
          // Encerra a verificação após 5 minutos
          setTimeout(() => {
            clearInterval(intervalId);
            if (toast.isActive(toastId)) {
              toast.dismiss(toastId);
              toast.error("Compra não realizada por tempo expirado");
              dispatch(setPurchaseStatus(''));
            }
          }, 300000); // 5 minutos
      
        } catch (error) {
          toast.dismiss(toastId);
          toast.error("Erro ao processar o pagamento. Por favor, tente novamente.");
          console.error("Erro ao criar a preferência de pagamento:", error);
        }
      };
      

      
      const requestPopupPermission = () => {
        if (!("Notification" in window)) {
          console.error("Este navegador não suporta notificações.");
          return;
        }
      
        if (Notification.permission !== "granted") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              console.log("Permissão de pop-up concedida.");
            } else {
              console.log("Permissão de pop-up negada.");
              // Você pode exibir uma mensagem ao usuário informando que os pop-ups são necessários
            }
          });
        }
      };
      
      // Supondo que esta função seja chamada quando a compra é concluída com sucesso
      function clearCart() {
      // Limpar o estado do componente
      setSelectedOriginalCourses([]);
      setSelectedPriceOptions({});
      setCourseCount(0); // Zerar a contagem de cursos no carrinho
      setAgreedToTerms(false); // Desmarca o checkbox de concordância com os termos
      dispatch(setPurchaseStatus('')); 
     
      }


      useEffect(() => {
        // Inicializar os valores padrão para selectedOriginalCourses e selectedPriceOptions
        setSelectedOriginalCourses([]);
        setSelectedPriceOptions({});
      }, []);
      
      const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
    
        if (searchTerm) {
          const filteredResults = cursosData.filter((curso) =>
            curso.titulo.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setSearchResults(filteredResults);
        } else {
          setSearchResults(cursosData);
        }
      };
      const handleCourseSelection = (e) => {
        e.preventDefault();
      
        toast.success("Os Cursos do nosso Catálogo estaram disponíveis em breve. Aguarde", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
      
      const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div >
      <section>
      <div className='back-aluno'><h1 className="welcome-message" style={{fontSize:'28pt'}}>Seja bem vindo a FMATCH, {username}.</h1></div>

      <div className="colunas-container"> 
   <div className='cursos-disponiveis'>
    <div className="icon-container">
      <FaBook size={40} /> {/* Ícone de livro */}
    </div>
    <h5>Cursos Disponíveis</h5>
    <Card.Text className="display-4">{cursosCompradosCatalogo.length}</Card.Text>
    <Link to="/usuario/central">
      <Button variant="primary" className="btn-suus" style={{ backgroundColor: '#14253A' }}>
        Assistir Aulas
      </Button>
    </Link>
  </div>

  <div className='editar-perfil'>
    <div className="icon-container">
      <FaUser size={40} /> {/* Ícone de usuário */}
    </div>
    <h5>Editar Perfil</h5>
    <Link to="/usuario/Perfil">
      <Button variant="primary" className="btn-suus" style={{ backgroundColor: '#14253A' }}>
        Ver meu Perfil
      </Button>
    </Link>
  </div>

  <div className='certificados'>
    <div className="icon-container">
      <FaCertificate size={40} /> {/* Ícone de certificado */}
    </div>
    <h5>Veja seus Certificados</h5>
    <Link to="/usuario/certificados">
      <Button variant="primary" className="btn-suus" style={{ backgroundColor: '#14253A' }}>
        Certificados
      </Button>
    </Link>
  </div>
</div>


      <hr />
      <div className='back-aluno'><h1 className="welcome-message" style={{fontSize:'18pt'}}>Veja seu historico de compras em seu perfil</h1></div>
   <div>

  <p className="periodo-acesso-explicacao" style={{fontSize:'12pt', fontFamily:'Montserrat', marginTop:'20px'}}>
          <strong>Como funciona o período de acesso?</strong><br />
          Após a compra do curso, a contagem do período de acesso é iniciada <strong>ao você abrir o módulo pela primeira vez ( 10 dias de acesso )</strong>. Ao concluir o Curso, <strong>você terá acesso a 2 visualizações de "Revisão" da Aula, após isso o acesso será revogado e você precisará comprar novamente.</strong>
  </p>
  
  <div className="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
  <div className="wistia_responsive_wrapper" style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}>
    <div className="wistia_embed wistia_async_awcr6g2vmu seo=true videoFoam=true" style={{ height: "100%", position: "relative", width: "100%" }}>
      <div className="wistia_swatch" style={{ height: "100%", left: 0, opacity: 0, overflow: "hidden", position: "absolute", top: 0, transition: "opacity 200ms", width: "100%" }}>
      <img src="https://fast.wistia.com/embed/medias/awcr6g2vmu/swatch" 
     style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%", opacity: imageLoaded ? 1 : 0 }} 
     alt="" 
     aria-hidden="true" 
     onLoad={() => setImageLoaded(true)} />
      </div>
    </div>
  </div>


          </div>

   </div>
    
  <Row className="user-profile-row">
    {cursosOriginais.map((curso) => (
      
      <Card className="programa-card" style={{width:'450px'}} key={curso.id}>
        <Card.Img variant="top" style={{height:'auto'}} src={curso.thumbnail} />
        <Card.Body>
          <Card.Title>{curso.nome}</Card.Title>
          <Card.Text className="card-text-container">
            {curso.descricao}
          </Card.Text>
          
          <Card.Text className="periodo-acesso-explicacao">
            
          <strong>Como funciona o período de acesso?</strong><br />
          Após a compra do curso, a contagem do período de acesso é iniciada <strong>ao você abrir o módulo pela primeira vez ( 10 dias de acesso )</strong>. Ao concluir o Curso, <strong>você terá acesso a 2 visualizações de "Revisão" da Aula, após isso o acesso será revogado e você precisará comprar novamente.</strong>
</Card.Text>
          {/* Incluindo os valores abaixo da descrição */}
          <div className="valores-cursos" style={{backgroundColor:'#14253A', borderRadius:'5px'}}>
          <p style={{fontFamily:'Montserrat', fontWeight:'bold', color:'White'}}>Acesso e Valor</p>
            <p style={{fontFamily:'Montserrat', fontWeight:'bold', color:'White'}}>10 dias - 2 revisões pós conclusão - R$ {curso.valor_10d}</p>
    
          </div>
          <Button style={{marginTop:'15px', }}
  className={`botao-aula ${selectedOriginalCourses.includes(curso.id) ? 'secondary' : 'primary'}`}
  onClick={() => !cursosCompradosCatalogo.includes(curso.id) && curso.nome !== "Módulo 7" && handleOriginalCourseSelection(curso.id)}
  disabled={cursosCompradosCatalogo.includes(curso.id) || curso.nome === "Módulo 7"}
>

    {curso.nome === "Módulo 7" 
      ? "DISPONÍVEL EM BREVE" 
      : cursosCompradosCatalogo.includes(curso.id)
        ? 'Curso já Adquirido' 
        : selectedOriginalCourses.includes(curso.id)
          ? 'Adicionado ao Carrinho'
          : 'COMPRAR'}
  </Button>
        </Card.Body>
      </Card>
    ))}
  </Row>



      <Container>
      <ToastContainer />
     
  
      <hr style={{ border: 'none', height: '2px', backgroundColor: '#050505', margin: '20px 0' }} />
      <h1 style={{fontFamily:'Montserrat'}}><center>Cursos de Treinamentos NR</center></h1>
      <h5><center>disponível em breve</center></h5>

      {/* 
      <div className="searchContainer">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Busca"
            value={searchTerm}
            onChange={handleSearch}
          />
          <div className="search-icon"></div>
        </div>
      </div>
      
     
  */}
    
    

   
    </Container>
      </section>
   
            
<Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
  
  <div className='back-aluno' style={{margin:'15px'}}><h1 className="welcome-message" style={{fontSize:'28pt'}}>Carrinho de Compras</h1></div>
  <Modal.Body>
    <Table striped bordered hover>
    <thead>
      <tr style={{fontFamily:'Montserrat'}}>
        <th>Nome do Curso</th>
        <th>Acesso de 10 dias</th>
     
        
      </tr>
    </thead>
 
    <tbody>
  {selectedOriginalCourses.map((courseId) => {
    const curso = cursosOriginais.find((curso) => curso.id === courseId);
    if (!curso) return null; // Garantir que o curso existe

    return (
      <tr key={curso.id} >
        <td style={{fontFamily:'Montserrat'}}>{curso.nome}</td>
        {/* Colunas para valores fixos de cada período */}
        <td style={{fontFamily:'Montserrat'}}>R$ {curso.valor_10d}</td>
    
        {/* Coluna para o valor selecionado */}
        
        
      </tr>
    );
  })}
  {/* Linha do Total */}
  <tr>
    <td colSpan="4" style={{fontFamily:'Montserrat'}}><strong>Total:</strong> {/* Ajuste no colspan para abranger todas as colunas anteriores */}
   <strong>R$ {calculateTotal().toFixed(2)}</strong></td>
  </tr>
</tbody>
  </Table>
  <hr />

<div style={{ maxHeight: '150px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px', border: '1px solid gray', padding: '10px', fontFamily:'Montserrat'  }}>
    <strong>CONTRATO DE PRESTAÇÃO DE SERVIÇOS</strong>
    <p>CURSOS RÁPIDOS</p>
    <p>Termos e Condições Gerais de Compra e Uso </p>
    <p>Pelo presente instrumento e na melhor forma de direito, em que são partes contratantes, de um lado, a
    FMATCH TECNOLOGIA LTDA, inscrito no CNPJ sob o nº 52.622.018/0001-29, com sede na cidade de
Lençóis Paulista, estado de São Paulo, Rua Coronel Álvaro Martins, 443, Vila nova Irerê, CEP 1682-180, e de
outro lado o "ALUNO", cujos dados se encontram cadastrados no formulário de pré-inscrição do curso na
modalidade de educação a distância vinculado eletronicamente a este termo, têm entre si justo e acordado o
presente contrato que se regerá pelas cláusulas e condições seguintes:
 </p>
    <p>1. Do Objeto </p>
    <p>1.1 Constitui objeto do presente contrato a compra e uso de serviços educacionais consistente em CURSOS
RÁPIDOS ORIGINAIS FMATCH para TREINAMENTO fornecidos pela FMATCH TECNOLOGIA
LTDA para o contratante ALUNO de acordo com o conteúdo especificado nas diretrizes de acesso na
aba do curso desejado que se enontra no endereço https://www.fmatch.com.br que foi
devidamente observado antes da compra.
 </p>
    <p>2. Definições </p>
    <p>2.1.Plataforma: Ambiente virtual que disponibiliza cursos online. </p>
    <p>2.2.ALUNO: Pessoa física que se cadastra na plataforma e adquiri os cursos. </p>
    <p>2.3.Curso: Conteúdo educativo curso rápido disponibilizado em videoaulas no formato da
tecnologia de streaming ou broadcast;
 </p>
    <p>2.4.Plataforma: Site e Área do ALUNO, acessível após login no sistema, disponível em
https://www.fmatch.com.br;
 </p>
    <p>2.5.Política de Privacidade: regras para proteção da privacidade do Usuário, bem como para trazer
transparência acerca do tratamento dos dados e informações pessoais que são
disponibilizados na Plataforma; </p>
    <p>2.6.Política de Proteção aos Direitos Autorais: regras para orientar Você sobre a proteção à
propriedade intelectual conferida pela Lei de Direitos Autorais, pela Constituição Federal, e
demais legislações aplicáveis;
 </p>
    <p>2.7.Material Didático: Apostilas, vídeos, exercícios e outros materiais eventualmente
disponibilizados para o aprendizado do Usuário. </p>
    <p>2.8.Instrutor: Pessoa responsável pelo conteúdo e acompanhamento do Curso. </p>
    <p>1.2 Das Responsabilidades </p>
    <p>1.2.1 A FMATCH se obriga a: </p>
    <p>2.8.1. Disponibilizar o modulo do curso especificado nas condições apresentadas no
endereço: https://www.fmatch.com.br/.
 </p>
    <p>2.8.2. Disponibilizar e permitir o acesso do ALUNO matriculado no curso durante o
período compreendido entre o início e o término estabelecido no programa. </p>
    <p>2.8.3. Disponibilizar no período previsto do curso os recursos didáticos e
atendimento conforme a descrição do programa. </p>
    <p>2.8.4. Emitir certificado de conclusão para o ALUNO que obteve o aproveitamento
mínimo estipulado para o curso após o seu encerramento.
 </p>
    <p>2.9.O ALUNO se obriga a: </p>
    <p>2.9.1. Ao se cadastrar o usuário deverá informar dados completos, recentes e
válidos, sendo de sua exclusiva responsabilidade manter referidos dados
atualizados, bem como o usuário se compromete com a veracidade dos dados
fornecidos. </p>
    <p>2.9.2. O ALUNO está ciente de que o mero cadastro na Plataforma não fornecerá
acesso aos Cursos da FMATCH, os quais só serão disponibilizados ao
ALUNO após comprovação de pagamento, conforme pormenorizado neste
contrato. </p>
    <p>2.9.3. Menores de 18 anos e aqueles que não possuírem plena capacidade civil
deverão obter previamente o consentimento expresso de seus responsáveis
legais para utilização da plataforma e dos serviços ou produtos, sendo de
responsabilidade exclusiva dos mesmos o eventual acesso por menores de
idade e por aqueles que não possuem plena capacidade civil sem a prévia
autorização.
 </p>
    <p>2.9.4. Mediante a realização do cadastro o usuário declara e garante expressamente
ser plenamente capaz, podendo exercer e usufruir livremente dos serviços e
produtos. </p>
    <p>2.9.5. Efetuar o pagamento do curso conforme opção no campo pagamento.
 </p>
    <p>2.9.6. Utilizar o curso de forma ética e responsável. </p>
    <p>2.9.7. Manter o login e senha em sigilo. </p>
    <p>2.9.8. Não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros,
responsabilizando-se integralmente pelo uso que deles seja feito.
 </p>
    <p>2.9.9. Fornecer um endereço de e-mail válido, através do qual o site realizará todas
comunicações necessárias.
 </p>
    <p>2.9.10. Manter confidencialidade de sua senha evitando o acesso indevido às
informações pessoais ou uso indevido. </p>
    <p>2.9.11. Realizar as atividades previstas no curso com o aproveitamento do conteúdo
ministrado dentro do limite de conclusão, até a data de término estabelecida
no Programa. </p>
    <p>2.9.12. Assegurar que o seu equipamento (hardware) e programas (software) são
compatíveis com as características técnicas que viabilize a utilização da
plataforma e dos serviços ou produtos, sendo inteiramente responsável pelas
condições de local e horário para seus estudos online, bem como pelo acesso
ao curso, e serviços de acesso à internet, telefone e outros recursos
necessários para o bom funcionamento do mesmo.
 </p>
    <p>4. Da computo do pagamento </p>
    <p>4.1.O ALUNO é responsável por verificar as informações de compra e pagamento antes de
confirmar a compra e o pagamento.
 </p>
    <p>4.2.O pagamento do curso se dará por meio da plataforma MERCADO PAGO, sendo tal plataforma
responsável pelos métodos de pagamento disponíveis e a gestão dos pagamentos. </p>
    <p>5. Do período para realização do curso
 </p>
    <p>5.1.Após computado o pagamento o curso será disponibilizado ao ALUNO para acesso.
 </p>
    <p>5.2.Ao abrir o módulo pela primeira vez, é reconhecido o acesso do ALUNO ao curso e considerado
como visualizado o primeiro acesso e concluído o curso rápido, iniciando-se o prazo de 10
(dez) dias de vigência em que o curso será disponibilizado ao ALUNO. </p>
    <p>5.3.Dentro do prazo de disponibilidade de 10 (dez) dias, ao ALUNO será permitido outras 2
(três) visualizações do mesmo curso rápido a título de “revisão de modulo” (posterior o
primeiro acesso), sendo certo que após o acesso a 2ª (segunda) “revisão de módulo”
disponibilizada ou decorrido o prazo de 10 (dez) dias do primeiro acesso, novos acessos ao
curso rápido serão automaticamente revogado.
 </p>
    <p>6. Disposições gerais </p>
    <p>6.1.Todos os Cursos e Conteúdos que são disponibilizados para o ALUNO na Plataforma os são
apenas no estado em que se encontram e tão somente para sua informação e uso pessoal na
forma designada FMATCH. Tais Cursos e Conteúdos não serão adaptados, EM NENHUMA
HIPÓTESE, à qualquer necessidade ou solicitação do ALUNO. </p>
    <p>6.2.O acesso à Plataforma será liberado ao Usuário após o preenchimento completo dos dados
cadastrais, com todas as informações obrigatórias solicitadas pela FMATCH com a
criação do respectivo login e senha para acesso.
 </p>
    <p>6.3.O ALUNO é o único responsável pelas informações por ele fornecidas quando de seu cadastro
na Plataforma, estando ciente de que a FMATCH não se responsabiliza por informações
incorretas ou inverídicas apresentadas pelo Usuário, o qual será responsável, também, por
manter atualizadas todas as informações pessoais e de contato fornecidas, especialmente seu
correio eletrônico (e-mail), telefone e endereço. </p>
    <p>6.4.O ALUNO reconhece que, caso seja constatado que este forneceu informações incorretas ou
inverídicas em seu cadastro, seu acesso à Plataforma poderá ser cancelado,
independentemente de qualquer formalidade, sem que nada seja devido pela FMATCH,
em razão de tal cancelamento.
 </p>
    <p>6.5.No acesso, navegação, cadastro e/ou utilização da Plataforma, aplicam-se as disposições
constantes na Política de Privacidade e Política de Proteção aos Direitos Autorais,
conjuntamente com estes Termos de Uso.
 </p>
    <p>6.6.A FMATCH poderá bloquear totalmente o acesso do ALUNO, independentemente de
prévio aviso, caso seja constatado qualquer atitude que possa colocar em risco a segurança e
a estabilidade do serviço, ou caso seja constado qualquer descumprimento do ALUNO.  </p>
    <p>6.7.A FMATCH se reserva o direito de alterar este instrumento a qualquer momento. Em
caso de dúvidas ou problemas, o Usuário deve entrar em contato com o suporte da
plataforma. </p>
    <p>6.8.A FMATCH não disponibiliza suas videoaulas em DVDs, pendrives ou qualquer tipo de
mídia de armazenamento, devendo o acesso ao Conteúdo ocorrer apenas na Plataforma. </p>
    <p>6.9. Após o término do Curso, o ALUNO não terá mais permissão para acessá-lo, devendo se
atentar às datas do cronograma disponibilizado pela FMATCH na Plataforma. </p>
    <p>6.10.O acesso a áreas restritas dentro da Plataforma somente é permitido aos Usuários
devidamente cadastrados, a partir do uso de seu login e senha, sendo tais áreas consideradas
fechadas. Neste sentido, o ALUNO fica ciente que se alguma falha no sistema for encontrada
que permita o seu acesso a qualquer área restrita da Plataforma, ainda que por mera
tentativa de erro e acerto de senha, ainda assim o ALUNO incidirá em sanções civis e criminais
decorrentes de sua conduta. </p>
    <p>6.11.O ALUNO poderá exercer seu direito de arrependimento previsto na Lei 8.098/1990 (Código
de Defesa do Consumidor), desde que não tenha assistido a mais de 30% (trinta por cento)
do conteúdo do Curso, podendo requerer o cancelamento do acesso ao curso adquirido e a
devolução dos valores pagos, através do email: conect.fam@gmail.com com o seguinte
título: “Cancelamento”. </p>
    <p>6.12. Reconhecendo o alcance mundial da Internet, o ALUNO concorda em cumprir qualquer
legislação do local onde está situado, bem como as leis vigentes na sede da FMATCH,
no Brasil e, ainda, a respeitar o disposto nestes Termos de Uso, Política de Privacidade e
Política de Proteção aos Direitos Autorais. </p>
    <p>6.13.O ALUNO reconhece que, em qualquer hipótese, será o único responsável pelo uso que fizer
da Plataforma, bem como por qualquer conteúdo ou comentário que nela inserir. </p>
    <p>6.14. Caso o ALUNO identifique qualquer material ofensivo, ilegal, ou atentatório à moral e aos
bons costumes, disponibilizado por outro Usuário da Plataforma, poderá, imediatamente
comunicar a FMATCH, através do email conect.fam@gmail.com com o título “abuso” ,
para que possa apurar a denúncia, ficando a FMATCH isenta de qualquer
responsabilidade por tal conteúdo, por ter sido realizado por terceiros, sem qualquer
intervenção ou controle da FMATCH.
 </p>
    <p>6.15. Ficará ao critério da administração da FMATCH a apuração das denúncias que lhe forem
dirigidas. </p>
    <p>6.16.O comportamento ilícito poderá ser sancionado com a suspensão ou cancelamento do
cadastro do Usuário na Plataforma, sem prejuízo da adoção das medidas judiciais cabíveis. </p>
    <p>7. Da Conclusão e Certificação </p>
    <p>7.1.Para ser certificado ao final do curso, o ALUNO deverá participar e concluir as atividades
propostas no prazo e obter o aproveitamento mínimo estipulado para o curso na data de seu
encerramento. </p>
    <p>7.2.Concluído o curso pelo ALUNO, caso necessário, este poderá solicitar a emissão do certificado
de conclusão. </p>
    <p>8. Exclusão de garantias e de responsabilidade da FMATCH </p>
    <p>8.1.A FMATCH não será, em hipótese alguma, responsável por quaisquer danos decorrentes
da interrupção do acesso à Plataforma ou falhas no seu funcionamento.</p>
    <p>8.2.A FMATCH utiliza as melhores práticas recomendadas de mercado para manter seguros
todos os dados inseridos por Você na Plataforma, entretanto, a FMATCH se exime de
responsabilidade por eventuais danos e prejuízos de toda natureza que decorram do
conhecimento que terceiros não autorizados tenham de quaisquer informações passadas pelo
ALUNO, em decorrência de falha exclusivamente atribuível ao ALUNO ou a terceiros que fujam
a qualquer controle razoável da FMATCH. </p>
    <p>8.3.A FMATCH não garante a ausência de softwares maliciosos quando da utilização de sua
Plataforma, bem como outros elementos nocivos que possam produzir alterações nos
sistemas informáticos dos Usuários (software e hardware) ou nos documentos eletrônicos
armazenados no sistema informático, eximindo-se de qualquer responsabilidade pelos danos
e prejuízos que possam decorrer da presença de vírus ou de outros elementos nocivos na
Plataforma. </p>
    <p>8.4.A FMATCH poderá, sem anuência ou concordância do Usuário, realizar quaisquer
alterações na Plataforma que julgar necessárias, sem que qualquer valor ou indenização seja
devida a Você em razão disso.
 </p>
    <p>8.5.Na máxima extensão permitida pela legislação aplicável, o valor máximo a que a FMATCH se responsabiliza, independente do motivo que originou o pedido, está limitado ao
montante eventualmente pago pelo ALUNO à FMATCH, desde que inequivocamente
comprovado o prejuízo alegado.
 </p>
    <p>8.6. Mesmo que qualquer parte destes Termos de Uso seja considerada inválida ou inexequível,
as demais disposições permanecerão em pleno vigor e efeito, sendo que o referido trecho
deverá ser interpretado de forma consistente com a lei aplicável, para refletir, na medida do
possível, a intenção original das partes. </p>
    <p>8.7. Eventual falha da FMATCH em exigir quaisquer direitos ou disposições dos presentes
Termos de Uso não constituirá renúncia, podendo exercer regularmente o seu direito, dentro
dos prazos legais. </p>
    <p>9. Dos Direitos Autorais
 </p>
    <p>9.1.Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário,
que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.
 </p>
    <p>9.2.Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que é
pessoal e intransferível. </p>
    <p>9.3.É terminantemente proibida a cópia, reprodução, no todo ou em partes do conteúdo do curso
os vídeos disponibilizados na plataforma são protegidos por direitos autorais e propriedade
intelectual.
 </p>
    <p>9.4.O ALUNO está autorizado a assistir os vídeos para fins de estudo, treinamento e educação </p>
    <p><strong>9.5.A transmissão do Curso ao ALUNO ocorrerá, exclusivamente, na Plataforma, sendo proibido
o armazenamento, download ou qualquer meio de gravação das videoaulas. </strong></p>
    <p><strong>9.6.Não é permitido que os cursos sejam repassados, copiados, reproduzidos, distribuídos,
transmitidos, difundidos, exibidos, vendidos, licenciados, adaptados ou, de outro modo,
explorados para quaisquer fins, sem o consentimento prévio e por escrito da FMATCH,
nem mesmo é permitido ao ALUNO reproduza para outras pessoas ou
compartilhe/distribuía ou ceda sua “revisão de modulo” para terceiros. </strong></p>
    <p><strong>9.7.É proibido qualquer forma de cópia, inclusive de instantâneos, quadro de imagens ou sons
dos cursos rápidos ou o uso dos cursos rápidos para qualquer finalidade que não seja a
expressamente autorizada exclusivamente ao ALUNO.  </strong></p>
    <p><strong>9.8.É proibida a utilização dos cursos para fins ilegais ou imorais.
 </strong></p>
    <p>9.9. O ALUNO deverá utilizar a Plataforma e todo o Conteúdo nela disponibilizado, incluindo os
Cursos, de acordo com o ordenamento jurídico brasileiro, com a moral e os bons costumes
geralmente aceitos, com os presentes Termos de Uso, Política de Proteção aos Direitos
Autorais e as demais instruções existentes na Plataforma, abstendo-se de usar, explorar,
reproduzir ou divulgar, indevidamente, por qualquer meio, o conteúdo disponibilizado na
Plataforma. </p>
    <p>9.10. Todo conteúdo disponibilizado na Plataforma, como marcas, logotipos, vídeos, arquivos,
textos, ícones, desenhos, sons, layouts, materiais didáticos, algoritmos, incluindo-se os
Cursos, são de propriedade exclusiva da FMATCH, ou de terceiros que concederam
autorização para tal utilização, e estão protegidos pelas leis e tratados internacionais, sendo
vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores
sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.279/96, 9.610/98
e 9.609/98, conforme detalhado na Política de Proteção aos Direitos Autorais. </p>
    <p>9.11.A violação dos direitos autorais pode resultar em sanções civis e criminai. </p>
    <p>9.12. Todas as marcas, nomes comerciais ou logotipos de qualquer espécie, disponibilizados na
Plataforma, são de propriedade da FMATCH, sem que a utilização da Plataforma possa
ser entendida como autorização para que o ALUNO possa citar as tais marcas, os nomes
comerciais e logotipos.
</p>
    <p>9.13.A FMATCH, ainda, se reserva o direito de recusar ou retirar o acesso à Plataforma, a
qualquer momento, e sem necessidade de prévio aviso, por iniciativa própria ou por
exigência de um terceiro, se o ALUNO descumprir, de qualquer forma, estes Termos de Uso,
as Políticas de Privacidade e Proteção aos Direitos Autorais e/ou a legislação vigente. </p>
    <p>9.14.O ALUNO reconhece que a FMATCH pode, a qualquer tempo, remover da Plataforma
qualquer Curso ou Conteúdo disponibilizado, sem necessidade de aviso prévio e sem que
nenhuma indenização seja devida em razão de tal remoção, com exceção das hipóteses
expressamente previstas no Contrato. </p>
    <p>9.15.O ALUNO se compromete a não produzir, reproduzir, disponibilizar, divulgar ou transmitir
qualquer conteúdo que: (i) Seja contrário a qualquer norma da legislação brasileira, bem
como à moral e aos bons costumes normalmente aceitos, ou que incentive qualquer forma
de racismo, discriminação ou violência; (ii)Seja protegido por quaisquer direitos de
propriedade intelectual ou industrial pertencente a terceiros, sem que Você tenha obtido
previamente dos seus titulares a autorização necessária para levar a cabo o uso que efetuar
ou pretender efetuar; (iii) Incorporem códigos maliciosos ou outros elementos físicos ou
eletrônicos que possam gerar danos ou impedir o normal funcionamento da rede, do sistema
ou de equipamentos informáticos (hardware e software) da CONNEC FAN ou de terceiros, ou
que possam causar dano aos documentos eletrônicos e arquivos armazenados nestes
equipamentos informáticos; (iv) Provoquem, por suas características (tais como forma,
extensão etc.) dificuldades no normal funcionamento do serviço. </p>
    <p>10. Outorga final </p>
    <p>10.1.Os presentes Termos de Uso serão regidos, interpretados e executados de acordo com as leis
da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de
outros estados ou países, sendo competente o Foro de Lençóis Paulisa/SP, no Brasil, para
dirimir qualquer dúvida decorrente deste instrumento, renunciando expressamente, neste
ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.
 </p>
    <p>10.2. E por estarem assim justas e contratadas, o ALUNO, ao clicar no botão "CONCORDO" assina
eletronicamente o presente contrato e declara estar de acordo com os Termos de Uso para
Serviços de Curso Online, aderindo às condições, que têm a mesma validade jurídica de um
documento impresso </p>
    <p>10.3. Caso Você não concorde com os presentes Termos de Uso, recomendamos que não prossiga
com o cadastramento na Plataforma, bem como que se abstenha de acessá-la e utilizá-la. </p>
   
  </div>
 
  <FormControlLabel
    control={<Checkbox checked={agreedToServiceTerms} onChange={(e) => setAgreedToServiceTerms(e.target.checked)} name="agreedToServiceTerms" />}
    style={{fontFamily:'Montserrat'}}
    label="Concordo com os Termos e Condições de Serviço."
  />


    
  </Modal.Body>
  <Modal.Footer>
  <Button style={{ backgroundColor: '#15283E', fontFamily:'Montserrat'}} variant="secondary" onClick={() => setShowModal(false)}>Fechar</Button>
  <Button
  style={{ backgroundColor: '#15283E' , fontFamily:'Montserrat', marginBottom:'15px'}}
  variant="primary"
  onClick={handleSubmit}
  disabled={!agreedToServiceTerms || selectedOriginalCourses.length === 0 || purchaseStatus === 'pendente'}
>
  Ir para Pagina de Pagamento
</Button>
</Modal.Footer>

</Modal>

<div className="floating-cart-btn" onClick={() => setShowModal(true)}>

            <div className="cart-count">{courseCount}</div>
  <img src={ImageCarrinho} alt="Carrinho" style={{ width: '45px', height: '45px' }} />
</div>
    </div>
    
  );
}

export default Curso;