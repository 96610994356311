import React, { useState, useEffect,useContext  } from 'react'; 
import { Badge, Button, Container, Row, Card, Modal } from 'react-bootstrap';
import cursosData from './cursos.json';
import CatalogoXLSX from '../views/Catalogo Cursos EAD - Atualizado 31.05.23.xlsx';
import axios from 'axios';
import './PainelUsuario.css';
import courseThumbnail from '../images/courseThumbnail.jpg';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { AuthContext } from '../context/AuthContext';
import { setSelectedCurso } from '../redux/actions/userActions';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FaBook, FaUsers,FaUser, FaCalendarAlt,FaMoneyBillWave, FaExclamationCircle, FaTimesCircle, FaCheckCircle  } from 'react-icons/fa';

const PainelUsuario = () => {
  const { authState } = useContext(AuthContext);
  const username = authState.username; // Acessando o username do contexto
  const userId = authState.userId; // Acessando o userId do contexto
  const timeZone = 'America/Sao_Paulo';
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(cursosData);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [cursosComprados, setCursosComprados] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedCursoId, setSelectedCursoId] = useState(null);
  const [selectedAulaAtualId, setSelectedAulaAtualId] = useState(null);
  const dispatch = useDispatch();

  const handleCardClick = async (curso) => {
    if (curso.status === 'concluido' && curso.acessos_pos_conclusao >= 2) {
      try {
        // Chamar a API para deletar o curso da tabela compras_curso
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/cursos-comprados/${curso.id}`, {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
  
        // Atualizar a lista de cursosComprados localmente
        setCursosComprados(
          cursosComprados.filter((c) => c.id !== curso.id)
        );
  
        toast.info(
          `Você já acessou 2x as Aulas de Revisão do curso ${curso.nome}. Seu acesso foi revogado.`
        );
      } catch (error) {
        console.error('Erro ao excluir o curso:', error);
        toast.error('Erro ao revogar o acesso ao curso.');
      }
    } else {
      // Lógica existente para quando o curso ainda pode ser acessado
      if (!curso.data_inicio_acesso || curso.data_inicio_acesso === 'Não iniciado') {
        setSelectedCursoId(curso.id);
        setShowConfirmationModal(true);
      } else {
        // Verifica se o usuário ainda tem acesso ao curso
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verificar-acesso/${userId}/${curso.id}`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
  
          if (response.data.temAcesso) {
            history.push(`/aula/${curso.id}`);
            dispatch(setSelectedCurso(curso));
          } else {
            if (response.data.motivo === 'acesso_excedido') {
              toast.info(`Você já acessou 2x as Aulas de Revisão do curso ${curso.nome}. Seu acesso foi revogado.`);
            } else {
              toast.error('Você não tem acesso a este curso.');
            }
          }
        } catch (error) {
          console.error('Erro ao verificar acesso ao curso:', error);
          toast.error('Erro ao verificar acesso ao curso.');
        }
      }
    }
  };
  const iniciarCurso = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/cursos/acesso/${selectedCursoId}`, { userId, cursoId: selectedCursoId })
      .then(response => {
        console.log(response.data.message);
        // Atualiza o curso selecionado no Redux
        const cursoIniciado = { ...response.data.curso, id: selectedCursoId }; // Adapte conforme a resposta da API
        dispatch(setSelectedCurso(cursoIniciado));
        setShowConfirmationModal(false);
        history.push(`/aula/${selectedCursoId}`);
      })
      .catch(error => {
        console.error('Erro ao registrar acesso ao curso:', error);
        toast.error('Erro ao iniciar o curso.');
        setShowConfirmationModal(false);
      });
  };
  
useEffect(() => {
  setIsLoading(true);
  axios.get(`${process.env.REACT_APP_API_URL}/api/cursos-comprados/`, {
      headers: {
        Authorization: `Bearer ${authState.token}`
      }
    })
    .then(async response => {
      const statusPromises = response.data.map(curso => {
        if (userId && curso.id) { // Garante que userId e curso.id são válidos
          return axios.get(`${process.env.REACT_APP_API_URL}/api/cursos/status/${userId}/${curso.id}`, {
              headers: {
                Authorization: `Bearer ${authState.token}`
              }
            })
            .then(statusResponse => ({
              ...curso,
              status: statusResponse.data.status || 'Não Iniciado',
            }))
            .catch(error => {
              console.error(`Erro ao buscar o status do curso ${curso.id}:`, error);
              return { ...curso, status: 'Erro ao buscar status' };
            });
        }
        return Promise.resolve({ ...curso, status: 'Informação de curso inválida' });
      });

      const cursosComStatus = await Promise.all(statusPromises);
      setCursosComprados(cursosComStatus);
      setIsLoading(false);
    })
    .catch(error => {
      console.error('Erro ao buscar cursos comprados:', error);
      setIsLoading(false);
    });
}, [userId, authState.token]);

const parseDate = dateStr => {
  // Removendo o 'Z' para tratar como hora local e não UTC
  const localDateStr = dateStr.replace('Z', '');
  return format(parseISO(localDateStr), 'dd/MM/yyyy');
};
  return (
    <div >
      <ToastContainer />
      <div className='back-aluno'><h1 className="welcome-message" style={{fontSize:'28pt'}}>Central Aluno</h1></div>
      <hr />

      <div className='back-aluno'><h1 className="welcome-message" style={{fontSize:'18pt'}}>Seus Cursos Disponiveis:</h1>
    
      </div>

      <center>
        <p className="periodo-acesso-explicacao" style={{fontSize:'12pt', fontFamily:'Montserrat', marginTop:'25px'}}>
          <strong>Como funciona o período de acesso?</strong><br />
          Após a compra do curso, a contagem do período de acesso é iniciada <strong>ao você abrir o módulo pela primeira vez ( 10 dias de acesso )</strong>. Ao concluir o Curso, <strong>você terá acesso a 2 visualizações de "Revisão" da Aula, após isso o acesso será revogado e você precisará comprar novamente.</strong>
        </p>
      </center>
      
      <div className='back-inventario' style={{ marginTop: '25px' }}>
        <Row className="user-profile-row">
          {isLoading ? (
            <div>Carregando cursos disponíveis...</div>
          ) : cursosComprados.length > 0 ? (
            cursosComprados.map(curso => (
              <div key={curso.id} className="curso-item1"> {/* Nova div para cada curso */}
                <div className="curso-detalhes1">
                  <div className="curso-imagem1">
                    <img src={curso.thumbnail || courseThumbnail} alt={curso.nome} />
                  </div>
                  <div className="curso-nome1">
                    {curso.nome}
                  </div>
                  <div className="curso-periodo1">
                    <FaCalendarAlt /> Período: {curso.periodo === '10d' ? '10 Dias' : curso.periodo === '6m' ? '6 Meses' : 'Indefinido'} {/* Verificar se o período é 10d ou 6m */}
                  </div>
                  <div className="curso-acesso1">
                    <div>
                      <FaCalendarAlt /> Acesso Iniciado: {curso.data_inicio_acesso ? parseDate(curso.data_inicio_acesso) : '...'}
                    </div>
                    <div>
                      <FaCalendarAlt /> Fim do acesso: {curso.data_fim_acesso ? parseDate(curso.data_fim_acesso) : '...'}
                    </div>
                  </div>
                  <div className="curso-progresso1">
                    <FaCheckCircle /> Progresso: {curso.status === 'iniciado' ? 'Iniciado' : curso.status === 'concluido' ? 'Concluído' : 'Não Iniciado'} {/* Exibir o progresso de forma mais clara */}
                  </div>
                </div>
                <div className="curso-acessar1">
                  <Button
                    variant="primary"
                    onClick={() => handleCardClick(curso)}
                    className="botao-acessar1"
                  >
                    Acessar Curso
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <center><div>Nenhum curso disponível ainda, veja nosso catálogo.</div></center>
          )}
        </Row>
      </div>
      

      <hr />
      
    
      {/* Modal de confirmação */}
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} className='modal-centered' centered>
        
          <Modal.Title style={{fontFamily: 'Montserrat', fontWeight: 'bold', textAlign: 'center', fontSize: '18pt'}}>Deseja iniciar o Curso?</Modal.Title>
       
        <Modal.Body style={{fontFamily: 'Montserrat',  textAlign: 'center', fontSize: '13pt'}}>
         O curso não foi iniciado. Ao clicar em "Iniciar Curso" a contagem de de acesso começará, conforme instrução acima.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}  className='botao-aula' style={{margin:'5px'}}>
            Voltar
          </Button>
          <Button variant="primary" onClick={iniciarCurso} className='botao-aula'>
            Iniciar Curso
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
  );
};

export default PainelUsuario;
