import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Row, Col, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

function EmpresaCadastro() {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();

  const handleSubmit = async (values) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
      await axios.post(`${apiUrl}/api/empresas`, values);
      console.log('Solicitação de cadastro enviada com sucesso!');
      form.resetFields();
      setIsModalVisible(true);
    } catch (error) {
      console.error('Erro ao enviar solicitação de cadastro:', error);
      // Implemente a lógica para exibir mensagem de erro aqui
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  // Redirecionamento após 10 segundos
  useEffect(() => {
    let timer;
    if (isModalVisible) {
      timer = setTimeout(() => {
        history.push('/login');
      }, 10000); // 10 segundos em milissegundos
    }
    return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
  }, [isModalVisible, history]);

  // Função de validação personalizada para CNPJ
  const validateCNPJ = (_, value) => {
    if (!value || validator.isTaxID(value, 'pt-BR')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Por favor, insira um CNPJ válido'));
  };

  // Função de validação personalizada para telefone
  const validatePhone = (_, value) => {
    if (!value || validator.isMobilePhone(value, 'pt-BR')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Por favor, insira um número de telefone válido'));
  };

  return (
    <div style={{ 
        backgroundImage: `url("https://i.imgur.com/dB9ehsc.png")`, // Define a imagem como background
        backgroundSize: 'cover', // Ajusta a imagem para cobrir todo o background
        backgroundRepeat: 'no-repeat', // Impede a repetição da imagem
        minHeight: '100vh', // Define a altura mínima como 100% da altura da viewport
      }}>
    <Row justify="center" style={{ padding: '20px' }}>
      <Col
        xs={24}
        sm={18}
        md={12}
        lg={8}
        xl={12}
        style={{
          backgroundColor: '#fff',
          padding: '30px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <center>
            <h2 style={{ fontFamily: 'Montserrat' }}>Cadastro de Empresa</h2>
            <p style={{ fontFamily: 'Montserrat' }}>
              Solicite um acesso ao painel administrativo empresarial para compra de pacotes de cursos ao seus colaboradores. Ao enviar o cadastro, será feita uma analise e entraremos em contato pelo WhatsApp.
            </p>
          </center>
          <hr />
          <Form.Item label="Nome da Empresa" name="nomeEmpresa" rules={[{ required: true, message: 'Por favor, insira o nome da empresa' }]}>
            <Input />
          </Form.Item>
          <Form.Item 
            label="CNPJ" 
            name="cnpj" 
            rules={[
              { required: true, message: 'Por favor, insira o CNPJ' },
              { validator: validateCNPJ }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Razão Social" name="razaoSocial" rules={[{ required: true, message: 'Por favor, insira a razão social' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Endereço" name="endereco" rules={[{ required: true, message: 'Por favor, insira o endereço' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Cidade" name="cidade" rules={[{ required: true, message: 'Por favor, insira a cidade' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Estado" name="estado" rules={[{ required: true, message: 'Por favor, insira o estado' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="CEP" name="cep" rules={[{ required: true, message: 'Por favor, insira o CEP' }]}>
            <Input />
          </Form.Item>
          <Form.Item 
            label="Telefone" 
            name="telefone" 
            rules={[
              { required: true, message: 'Por favor, insira o telefone' },
              { validator: validatePhone }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            label="Email" 
            name="email" 
            rules={[
              { required: true, message: 'Por favor, insira um email' },
              { type: 'email', message: 'Por favor, insira um email válido' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            label="Senha" 
            name="senha" 
            rules={[
              { required: true, message: 'Por favor, insira a senha' },
              { min: 8, message: 'A senha deve ter pelo menos 8 caracteres' }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <center>
              <Button type="primary" style={{ backgroundColor: '#FF7F00', fontFamily:"Montserrat", fontSize:"20px" }} htmlType="submit">
                Solicitar Cadastro
              </Button>
            </center>
          </Form.Item>
        </Form>
      </Col>

      <Modal 
        title="Solicitação Enviada" 
        visible={isModalVisible} 
        closable={false}
        footer={null}
      >
        <p style={{ fontFamily: 'Montserrat' }}>Sua solicitação de cadastro foi enviada! Daremos um retorno por e-mail e entraremos em contato em 24 horas.</p>
        <p style={{ fontFamily: 'Montserrat' }}>Você será redirecionado para a página de login em 10 segundos.</p>
      </Modal>
    </Row>
    </div>
  );
}

export default EmpresaCadastro;